
@keyframes fadeInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(20px); 
    }
    100% {
      opacity: 1; 
      transform: translateY(0);
    }
  }

.header{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
    background-color: white;
    color: black;
    animation: fadeInFromBottom 1s ease forwards;
    border-bottom: 0.5px solid lightgray;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}

a{
    text-decoration: none;
    color: black;
}

.contactNavBar{
    display: flex;
    padding-right: 20px;
}

.contactNavBar > img{
    padding-right: 10px;
}

.contactNavBar a {
    text-decoration: none;
    color: black;
}

.calendarAndCountDownContainer{
    display: flex;
    gap: 10px;
    margin-left: -60px;
}

.navBarHeader{
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 10px;
    height: 80px;
    animation: fadeInFromBottom 1s ease forwards;
    border-bottom: 0.5px solid lightgray;
 
}

.mosqueLogo img{
    width: 55px;
    height: 50px;
    position: relative; 
}

.navBarHeader ul{
    list-style: none;
    color: black;
    display: flex;
    gap: 70px;
    justify-content: center;
    align-items: center;
    font-size: 23px;
}

.navBarHeader ul li{
    cursor: pointer;
}

.navBarHeader ul li:hover{
    border-bottom: 3px solid rgb(10, 96, 48);
 
}
.mosqueName{
    font-size: 24px;
}

.donateBtn{
    border: 0;
    border-radius: 15px;
    padding: 15px;
    background-color: rgb(10, 96, 48);
    transition: background-color 0.3s ease;
    color: white;
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.donateBtn a {
    color: white;
    text-align: center;
}

.donateBtn:hover{
    background-color: #CDAC2F;
    border-bottom: 0px !important;
}


.confirmScreenOverlay{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    color: black;
    z-index: 1;
    background-color: rgba(0,0,0, 0.3);
}

.confirmScreenContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30vh;
    width: 30vw;
    background-color: rgb(10, 96, 48);
    z-index: 2;
    border-radius: 15px; 
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    text-align: center; 
    padding: 0 30px;
}

.confirmScreenTitle{
    font-size: 25px;
    margin-bottom: 20px;
}

.confirmScreenMessage{
    font-size: 20px;
}

.copyButton{
    background-color: #CDAC2F;
    margin-top: 20px;
    width: 30vw;
    margin: 20px auto 0;
}


@media only screen and (max-width: 900px) {
    .mobileMosqueLogo{
        width: 25%;
    }

    .copyButton{
        width: auto; 
    }

    .confirmScreenContainer{
        height: 50vh;
         width: 70vw;
    }
    


    .logoAndNav{
        display: flex;
        justify-content: center;
        gap: 40px;
        align-items: center; 
    }

    .logoAndNav .donateBtn{
        margin-left: -30px;
        width: auto;
    }

    

    .mobileNavOpen{
        height: 100vh;
        position: absolute;
        z-index: 10;
        top: 0;
        background-color: white;
        right: 0;
        width: 70vw;
        animation: slideInFromRight 1s ease forwards;
    }

    .donateBtn{
        width: 40vw;
        padding: 10px;
        font-size: 20px;
    }

    .mobileNavOpen ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 20px;
    }

    .closeOutModal{
        margin-top: 20px;
        margin-left: 10px;
        font-family: Arial, Helvetica, sans-serif;
        background-color: rgb(10, 96, 48);
        width: 5vw;
        text-align: center;
        border-radius: 10px;
        color: white;
        box-shadow: 0px 0px 10px  0px rgba(0,0,0,.1);
    }

    .calendarAndCountDownContainer{
        margin: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid lightGray;
        box-shadow: 0px 0px 10px  0px rgba(0,0,0,.1);
    }

    .header{
        box-shadow: 0px 0px 0px  0px rgba(0,0,0,0);
        border: 0;
        height: auto;
        text-align: center;
        flex-direction: column;
        margin-top: 10px;
    }




}