@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(20px); 
  }
  100% {
    opacity: 1; 
    transform: translateY(0);
  }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%); /* Starts off the screen to the right */
    }
    100% {
        opacity: 1;
        transform: translateX(0); /* Ends at its final position */
    }
}

.mainContainer{
    display: flex;
    flex-direction: row ;
    align-items: center;
    padding-top: 10px;
}

.masjidImage{
    width: 33.3333vw;
    float: right;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    animation: fadeInFromBottom 1s ease forwards; 

}

.prayerTimesContainer{
    width: 33.333vw;
    display: flex;
    animation: fadeInFromBottom 1s ease forwards; 
}

.prayerTimesTable{
    background-color: white;
    width: 400px;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    font-size: 25px;
}

.prayerHeader{
    display: flex;
    gap: 4px;

}

.prayerTitle, .prayerTitle2, .eventsTitle, .quranTitle{
    color: rgb(10, 96, 48);
    font-weight: 700;
}
  .prayerTableHomePage tr.upcomingPrayer th {
    background-color: #CDAC2F; /* Your desired background color */
}
  
  .prayerTableHomePage tr.upcomingPrayer th:first-child {
    border-top-left-radius: 10px; /* Adjust as needed */
    border-bottom-left-radius: 10px; /* Adjust as needed */
  }
  
  .prayerTableHomePage tr.upcomingPrayer th:last-child {
    border-top-right-radius: 10px; /* Adjust as needed */
    border-bottom-right-radius: 10px; /* Adjust as needed */
  }



strong{
    color: rgb(10, 96, 48);
}


.divider1 {
    position: relative;
    /* Ensure there's enough space for the border */
    padding: 1.75px; /* Adjust this value to match the desired border thickness */
    z-index: 2;
    margin: 8px 0;
}
  
.divider1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Create a gradient border */
    background: linear-gradient(to right, #CDAC2F, white);
    /* Simulate the border */
    z-index: -1;
    border-radius: inherit; /* Optional for rounded corners */
}

.divider{
    width: 100%;
    border: 1px solid gray;
}

.prayerTableHomePage tbody tr {
    border-bottom: 1px solid lightGray; 
  }
  
.prayerTableHomePage {
    border-collapse: collapse;
}


.prayerTableHomePage th, .prayerTableHomePage td {
    padding: 8px;
 }

.prayerTableHomePage tbody tr:last-child {
   border-bottom: none;
}

.prayerTableHomePage tr.currentPrayer {
    background-color: #d3f8d3; /* Or any color you prefer */
  }

.jummahInfoContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    text-align: center;
    border: 1.5px solid lightgray;
    margin-top: 10px;
    padding-top: 10px;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0, 0.1);
    height: 90px;
    border-radius: 15px;
}

.jummahInfoHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.jummahInfoHeader img{
    width: 45px;
}

.jummahInfoHeader div{
    font-size: 20px;
    width: 200px;
    margin-left: -50;
 
}

.jummahInfo{
    font-size: 20px;
}

span{
    color: gray;
    font-size: 14px;
}

.eventsAndAnnoucementsContainer{
    background-color: white;
    width: 33.333vw;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    gap: 200px;
    animation: fadeInFromBottom 1s ease forwards; 
}

.eventsContainer{

}

.quranClassContainer div:nth-child(3),
.quranClassContainer div:nth-child(4),
.quranClassContainer div:nth-child(5){
    text-align: center;
    font-size: 20px;
}

.ramadanContainer{
    width: 100%;
    border: 10px solid white;
    border-right: 0;
    border-left: 0;
    background: linear-gradient(106deg, rgb(131 89 118), rgb(131 89 118) 26%, rgb(0, 78, 192)), linear-gradient(106deg, rgba(71, 229, 238, 0), rgb(0, 78, 192) 28%, rgb(0, 78, 192) 74%);
    text-align: center;
    color: white;
    font-size: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.ramadanContainer >div:first-child {
    font-weight: 700;
    font-size: 50px;
}

.fastingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 300px;
    background-color: rgba(255,255,255,0.2);
    width: 80%;
    margin: 0 auto;
    padding: 60px;
}

.counterContainer{
    border: 4px solid white;
    width: 600px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    font-weight: 900;
    background: rgba(0,0,0, 0.1);
}

.suhoorContainer, .iftarContainer{
    border: 5px solid white;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    font-weight: 700;
    font-size: 30px;
}

.suhoorContainer>div:first-child, .iftarContainer>div:first-child{
    padding-bottom: 10px;
}

.athanAndImage{
    display: flex;
    gap: 10px;
    align-items: center;
    
}

.athanAndImage img{
    width: 40px;
}

.footerContainer{
    margin: 10px auto;
    width: 80%;
    display: flex;
    justify-content: center;
    padding: 50px;
    border-radius: 15px; 
    box-shadow: 0 0 100px rgba(0,0,0,.3);
}

.contactContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contactHome{
    display: flex;
    align-items: center;
}
.contactHome a, .contactHome div{
    text-decoration: none;
    color: black;
    padding-left: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dashed lightGray;
}

.ramadanCalendarContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
    object-fit: cover;
    font-size: 50px;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    color: rgb(10, 96, 48); 
    border: 7px solid #CDAC2F;    
}

.ramadanCalendarContainer img{
    object-fit: cover;
    margin: 0 auto;
    width: 40vw;
}
@media only screen and (max-width: 800px) {

    .ramadanCalendarContainer{
        font-size: 30px;
    }

    .ramadanCalendarContainer img{
        width: 95vw;
    }    

    .ramadanContainer{
       width: 100vw;
       border: 0px;
       padding: 50px 0 ;
    }

    .ramadanContainer >div:first-child {
        font-weight: 700;
        font-size: 25px;
        text-align: center;
    }

    .counterContainer{
        width: 90vw;
        border: 0;
    }

    .fastingContainer{
        flex-direction: column;
        gap: 20px;
        padding: 10px 0;
    }

    .mainContainer{
        flex-direction: column;
        align-items: flex-start;
    }

    .masjidImage{
        display: none;
    }

    .prayerTimesContainer, .eventsAndAnnoucementsContainer{
        width: 90vw;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 30px;
        font-size: 20px;
    }

    .prayerTimesTable{
        padding-left: 0;
        font-size: 20px;
    }

    .footerContainer{
        width: auto;
    }




}







  



